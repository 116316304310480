<template>
  <div>
    <Modal
      v-for="modal in getOpenedModals"
      :key="modal.id"
      :title="modal.title"
    >
      <component
        :is="modal.name"
        :props-data="modal.propsData"
      />
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/modals/modal'

export default {
  name: 'ModalRender',
  components: {
    Modal,
    CreateSales: () => import(/* webpackChunkName: "create-sales" */ '@/components/modals/create-sales'),
    CreateModal: () => import(/* webpackChunkName: "create-modal" */ '@/components/modals/create-modal'),
    ChangeModal: () => import(/* webpackChunkName: "create-modal" */ '@/components/modals/change-modal'),
    InfoModal: () => import(/* webpackChunkName: "info-modal" */ '@/components/modals/info-modal'),
    BugReportModal: () => import(/* webpackChunkName: "bug-report-modal" */ '@/components/modals/bug-report-modal'),
    CreateOperatingSchedule: () => import(/* webpackChunkName: "create-operating-schedule" */ '@/components/modals/create-operating-schedule'),
  },
  computed: {
    ...mapGetters('modal', ['getOpenedModals'])
  }
}
</script>
