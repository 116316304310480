<template>
  <el-dialog
    visible
    :fullscreen="isMobile()"
    :width="width"
    :title="title"
    @close="closeModal(null)"
  >
    <slot></slot>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { isMobile } from '@/helper'

export default {
  name: 'Modal',
  data () {
    return {
      dialog: true
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    width () {
      return isMobile() ? '100%' : '50%'
    }
  },
  mounted () {
    document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    isMobile
  }
}
</script>

<style lang="scss" src="./style.scss" />
